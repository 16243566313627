import { gql, useLazyQuery } from '@apollo/client';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_EMAIL_COMPILED_HTML = gql`
  query getEmailCompiledHtml($emailAssetContext: EmailAssetContext!) {
    getEmailCompiledHtml(emailAssetContext: $emailAssetContext)
  }`;

type ResultType = {
  getEmailCompiledHtml: string[];
};

const useGetEmailCompiledHtml = () => {
  const [queryGetEmailCompiledHtml, { data, loading, error, called }] = useLazyQuery<ResultType>(GET_EMAIL_COMPILED_HTML, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getEmailCompiledHtml.ts - useGetEmailCompiledHtml',
    });
  }

  return {
    queryGetEmailCompiledHtml,
    data: data?.getEmailCompiledHtml,
    loading,
    error,
    called,
  };
};

export default useGetEmailCompiledHtml;
