import { gql, useMutation, MutationResult } from '@apollo/client';
import { useCallback } from 'react';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const EMPTY_S3_DIRECTORY = gql`
mutation emptyS3Directory($prefix: String!) {
    emptyS3Directory(prefix: $prefix)
  }`;

type ResultType = {
  emptyS3Directory: boolean | null | undefined;
};
type UseEmptyS3DirectoryMutation = [
  (prefix: string) => Promise<boolean | null | undefined>,
  MutationResult<ResultType>,
];

export const useEmptyS3Directory = (): UseEmptyS3DirectoryMutation => {
  const [emptyS3Directory, state] = useMutation<ResultType>(EMPTY_S3_DIRECTORY);

  const executeMutation = useCallback(async (prefix) => {
    try {
      const data = await emptyS3Directory({
        variables: { prefix },
      });

      return data.data?.emptyS3Directory;
    } catch (error: unknown) {
      if (error instanceof Error) {
        const customContext = extractApolloErrorDetails(error, prefix);
        Logger.logError({
          error,
          customContext,
          transactionName: 'emptyS3Directory.ts - emptyS3DirectoryMutation',
        });
      }
      return null;
    }
  }, [emptyS3Directory]);

  return [executeMutation, state];
};
