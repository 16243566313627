import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { useLazyGetS3FileQuery } from 'basics/graphql/queries/getS3File';
import { useLazyGetSecretQuery } from 'basics/graphql/queries/getSecret';
import { getUTCDate } from 'basics/utils/dates/dates.utils';
import { errorToast, succesToast } from 'basics/utils/toast';
import { EventStatus, HybridEvent, Market, OnlineEvent, PhysicalEvent, RegisterParticipantInput } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { previewDomainLinks } from 'modules/events/basics/constants/event.constants';
import { testUserList } from 'modules/events/basics/constants/eventTesting.constants';
import useEventTestingUserListOptions from 'modules/events/basics/options/eventTestingUserList.options';
import { Participant, Event } from 'modules/events/basics/types/events.types';
import { HybridMeetingDataState } from 'modules/events/basics/types/hybridMeeting.types';
import { OnlineMeetingDataState } from 'modules/events/basics/types/onlineMeeting.types';
import { PhysicalMeetingDataState } from 'modules/events/basics/types/physicalMeeting.types';
import { isDraft, prettifyEventUrl } from 'modules/events/basics/utils/events.utils';
import { MAILINATOR_URL } from 'modules/events/components/EventTesting/EventTesting.constants';
import useEventTestingSchema from 'modules/events/components/EventTesting/EventTesting.schema';
import { EventTestingConfig } from 'modules/events/components/EventTesting/EventTesting.types';
import { filterTestUsers, getE2EVipLink } from 'modules/events/components/EventTesting/EventTesting.utils';
import { useEmptyS3Directory } from 'modules/events/graphql/mutations/emptyS3Directory';
import { useInviteParticipantMutation } from 'modules/events/graphql/mutations/inviteParticipant';
import { useTestEventMutation } from 'modules/events/graphql/mutations/testEvent';
import { useUpdateScreenshotPdfUrlMutation } from 'modules/events/graphql/mutations/updatePdfScreenshotUrl';
import useLazyGetFullPrivateEventById from 'modules/events/graphql/queries/getLazyFullPrivateEventById';
import useGetEmailCompiledHtml from 'modules/events/graphql/queries/getSfmcEmailHtml';
import eventState from 'modules/events/states/events.states';
import Logger from 'services/logger/logger';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useEventTesting = () => {
  const { t } = useTranslation();
  const flags = useFlags();

  const [participants, setParticipants] = useState<Participant[] | null>(null);
  const [mailinatorUrl, setMailinatorUrl] = useState<string>('');
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [veevaSyncModalIsOpen, setVeevaSyncModalIsOpen] = useState(false);
  const [veevaSyncTypeModalIsOpen, setVeevaSyncTypeModalIsOpen] = useState(false);
  const [screenshotProgress, setScreenshotProgress] = useState<string | null>(null);
  const event = useSnapshot(eventState.createEvent) as Event;
  const { status, _id: eventId, title, lastTestAt, data, presence } = event;
  const [pdfUrl, setPdfUrl] = useState<string | undefined | null>(data?.screenshotPdfUrl);
  const market = event?.data?.biogenLincConfig?.isSwitzerlandSpecific ? Market.ch : event?.market;
  const publicRegistrationEnabled = data?.biogenLincConfig?.enableGuestRegistration || false;
  const cxBannerEnabled = !!(data as OnlineEvent | PhysicalEvent | HybridEvent)?.cxSurveyActivation
  || !!(data as HybridEvent)?.physicalCxSurveyActivation;

  const { providerFields, useWatchForm, getValues, useWatchField } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const selectedUserField = useWatchField(commonFields.testUserList);

  const validations = useEventTestingSchema();

  const isPublished = !isDraft(status);
  const isReady = status === EventStatus.ready && eventState?.createEvent?._id;
  let generating = false;
  let pingTimeout: NodeJS.Timeout;

  const [testEvent, { loading }] = useTestEventMutation();
  const [runInviteParticipantMutation] = useInviteParticipantMutation();
  const { queryGetS3File } = useLazyGetS3FileQuery();
  const { queryGetSecret } = useLazyGetSecretQuery();
  const { queryGetEmailCompiledHtml } = useGetEmailCompiledHtml();
  const [emptyS3Directory] = useEmptyS3Directory();
  const [updateScreenshotPdfUrl] = useUpdateScreenshotPdfUrlMutation();
  const { queryGetFullPrivateEventById, data: freshEvent } = useLazyGetFullPrivateEventById();

  const refreshListFunction = () => {
    queryGetFullPrivateEventById({ variables: { eventId } });
  };

  useEffect(() => {
    if (data) {
      setParticipants(filterTestUsers(data?.participants) as Participant[] || null);
      setPdfUrl(data?.screenshotPdfUrl);
    }
  }, [data]);

  useEffect(() => {
    if (freshEvent) {
      setParticipants(filterTestUsers(freshEvent?.data?.participants) as Participant[] || null);
    }
  }, [data?.participants, freshEvent]);

  useEffect(() => {
    if (isValid && selectedUserField) {
      setMailinatorUrl(`${MAILINATOR_URL}${selectedUserField.value}`);
    }
  }, [isValid, selectedUserField]);

  const handleClick = async () => {
    const selectedUser = getValues();

    const selectedTestUser = testUserList.find((user) => {
      return user.email === selectedUser[commonFields.testUserList];
    });

    if (selectedTestUser && eventId && market) {
      await testEvent(eventId, selectedTestUser);
      const vipLink = await getE2EVipLink(
        selectedTestUser,
        title,
        eventId,
        market,
        flags.backofficeBiogenlinc5313GenerateVipLinks,
        isPublished,
      );
      window.open(vipLink, '_blank');
    }
  };

  const addPrivilegedUser = async () => {
    const selectedUser = getValues();
    const selectedTestUser = testUserList.find((user) => {
      return user.email === selectedUser[commonFields.testUserList];
    });

    if (selectedTestUser && eventId && market) {
      const testParticipant: RegisterParticipantInput = {
        lastName: selectedTestUser.lastName,
        firstName: selectedTestUser.firstName,
        mdmId: selectedTestUser.mdmid,
        specialty: '',
        restricted: false,
        email: selectedTestUser.email,
        eventId,
      };
      const success = await runInviteParticipantMutation(testParticipant);

      if (success && event?.data.participants) {
        setParticipants(event.data.participants);
        succesToast('event_register_participant_sucess');
      }
    }
  };

  const emptyTmpDirectory = async () => {
    await emptyS3Directory('tmp');
  };

  const downloadPdf = async () => {
    const { data: { getS3File: url } } = await queryGetS3File({ variables: { title: eventId } });
    if (url) {
      const screenshotUrl = await updateScreenshotPdfUrl(eventId, url);
      setPdfUrl(screenshotUrl);
      succesToast(`${t('event_test_download_screenshot_success', { eventId })}`);
    } else {
      errorToast(`${t('event_test_download_screenshot_error', { eventId })}`);
    }
  };

  const uploadToVeevaClick = async () => {
    if (pdfUrl) {
      setVeevaSyncTypeModalIsOpen(true);
    }
  };

  const generatePdfClick = async () => {
    let htmlUrls = null;

    if (flags.cweb282FetchSfmcEmailsTemplates) {
      const eventData = event?.data as HybridMeetingDataState | PhysicalMeetingDataState | OnlineMeetingDataState;
      const startDate = getUTCDate(
        eventData.session?.dateRange[0] || undefined, eventData.session?.startTime, eventData.session?.timeZone,
      ) || new Date();
      const endDate = getUTCDate(
        eventData.session?.dateRange[1] || undefined, eventData.session?.endTime, eventData.session?.timeZone,
      ) || new Date();

      const emailAssetContext = {
        title: event.title,
        country: eventData.isSwitzerlantSpecific ? Market.ch : event.market,
        language: event.locale,
        joiningchannel: event.presence,
        firstname: testUserList[0].firstName,
        lastname: testUserList[0].lastName,
        date: startDate,
        endDate,
        timezone: eventData.session?.timeZone,
        emailprccode: eventData.emailPrcCode || '',
        headernote: eventData.emailHeaderNote || '',
        footernote: eventData.emailFooterNote || '',
        biogenlogo: eventData.biogenLogo === '2',
        buttoncolor: eventData.buttonColor || '#1F49E0',
        signatureemailaddress: eventData.teamContactCheckbox ? eventData.teamContactEmail : 'webinars@biogen.com',
        signaturefromname: eventData.teamContactCheckbox ? eventData.teamContactDescription : 'Your Biogen Team',
        footerdisclaimertext: eventData.footerDisclaimerText,
        headernoteemailtypes: eventData.emailHeaderNoteEmailTypes,
        footernoteemailtypes: eventData.emailFooterNoteEmailTypes,
        agendafile: eventData.agendaFile?.length ? eventData.agendaFile[0].url : null,
        location: (eventData as PhysicalMeetingDataState | HybridMeetingDataState).eventLocation || '',
        programlogourl: eventData.programLogo?.length && eventData.biogenLogo !== '0' ? eventData.programLogo[0].url : null,
        author1: eventData.speakers?.length && eventData.speakers[0]?.firstName && eventData.speakers[0]?.lastName
          ? `${eventData.speakers[0]?.firstName} ${eventData.speakers[0]?.lastName}`
          : null,
        author2: eventData.speakers?.length && eventData.speakers[1]?.firstName && eventData.speakers[1]?.lastName
          ? `${eventData.speakers[1]?.firstName} ${eventData.speakers[1]?.lastName}`
          : null,
        author3: eventData.speakers?.length && eventData.speakers[2]?.firstName && eventData.speakers[2]?.lastName
          ? `${eventData.speakers[2]?.firstName} ${eventData.speakers[2]?.lastName}`
          : null,
        plink: null,
        questionid: null,
        veevacountry: null,
      };

      setPdfLoading(true);
      const response = await queryGetEmailCompiledHtml({ variables: {
        emailAssetContext,
      } });
      htmlUrls = response.data?.getEmailCompiledHtml;
    }

    const WS_URL = 'wss://contentful-pages-screenshots-generation.tooling.mybiogen.io';
    const socket = new WebSocket(WS_URL);
    const { data: { getSecret: pdfGeneratorToken } } = await queryGetSecret({ variables: { key: 'PDF_GENERATOR_TOKEN' } });
    const additionalHeaders = {
      'pdf-generator-token': pdfGeneratorToken,
    };

    if (eventId && market) {
      const prettyEventTitle = prettifyEventUrl(`${event.title}_${eventId}`);
      const urlSuffix = event.data?.biogenLincConfig?.isPublic ? '' : ':private';
      const previewLink = new URL(`${previewDomainLinks[market as keyof typeof previewDomainLinks]}${prettyEventTitle}`);
      const pdfCallData = {
        entryId: eventId,
        internalTitle: eventId,
        urls: [
          `${previewLink.toString()}${cxBannerEnabled ? '/?cxbanner=true' : ''}${urlSuffix}`,
          ...(publicRegistrationEnabled ? [`${previewLink.toString()}/registration${urlSuffix}`] : []),
          ...(htmlUrls || []),
        ],
      };

      const sendJsonMessage = (message: unknown) => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(message));
        } else {
          errorToast('Websocket is not open');
          socket.close();
        }
      };
      socket.onopen = () => {
        sendJsonMessage({
          action: 'StartExecution',
          body: {
            Input: pdfCallData,
            Headers: additionalHeaders,
            Method: 'POST',
          },
        });

        const ping = () => {
          sendJsonMessage({
            action: 'RequestProgress',
            body: {
              Input: {
                entryId: eventId,
              },
            },
          });
          pingTimeout = setTimeout(ping, 1000);
        };

        ping();
      };

      socket.onmessage = (msgEvent) => {
        if (msgEvent?.data) {
          setPdfLoading(true);
          try {
            const msgData = JSON.parse(msgEvent.data);

            if (msgData.status === 'error') {
              errorToast(msgData);
              socket.close();
            } else if (msgData.progress) {
              setScreenshotProgress(msgData.progress);
              // Set another generating var, except pdfLoading, but for properly handling the socket responses
              if (!generating) {
                generating = true;
              }
            } else if (!msgData.progress && generating) {
              generating = false;
              setScreenshotProgress(null);
              setPdfLoading(false);
              downloadPdf();
              emptyTmpDirectory();
              socket.close();
            }
          } catch (error: unknown) {
            if (error instanceof Error) {
              Logger.logError({
                error,
                transactionName: 'generatePdf',
              });
              errorToast(t('errors_standard'));
            }
            errorToast(t('errors_standard'));
          }
        }
      };

      socket.onclose = () => {
        if (pingTimeout) {
          clearTimeout(pingTimeout);
        }
      };
    }
  };

  const handlePdfClick = async () => {
    succesToast(t('event_testing_screenshot_generating_in_progress'));
    await generatePdfClick();
  };
  const eventTestingConfig: EventTestingConfig = {
    testUserList: {
      label: t('event_testing_test_user_list'),
      name: commonFields.testUserList,
      placeholder: t('event_testing_test_user_list_placeholder'),
      options: useEventTestingUserListOptions(),
      required: true,
      defaultValue: null,
      validation: validations.testUserList,
      disableClearable: true,
    },
    testButton: {
      label: t('event_testing_button'),
      type: 'submit',
      disabled: !isPublished && (!isReady || !isValid),
      onClick: () => { handleClick(); },
      loading,
    },
    generatePdfButton: {
      label: t('event_testing_generate_screenshot_button'),
      disabled: !isReady,
      onClick: () => { handlePdfClick(); },
      loading: pdfLoading,
    },
    insciteSyncButton: {
      label: t('event_testing_sync_screenshot_button'),
      disabled: !pdfUrl || pdfLoading,
      onClick: () => { uploadToVeevaClick(); },
      loading: pdfLoading,
    },
    addPrivilegedUser: {
      label: t('event_add_privileged_test_user_button'),
      type: 'submit',
      disabled: !isValid || !(event.data as HybridMeetingDataState).isRestrictedAccess,
      onClick: () => { addPrivilegedUser(); },
      loading,
    },
  };

  return {
    t,
    isPublished,
    providerFields,
    eventTestingConfig,
    eventId,
    event,
    presence,
    participants,
    setParticipants,
    lastTestAt,
    refreshListFunction,
    mailinatorUrl,
    handlePdfClick,
    pdfUrl,
    screenshotProgress,
    pdfLoading,
    veevaSyncModalIsOpen,
    setVeevaSyncModalIsOpen,
    veevaSyncTypeModalIsOpen,
    setVeevaSyncTypeModalIsOpen,
  };
};

export default useEventTesting;

